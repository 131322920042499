import React, { useState } from "react";
import crossIcon from "../../assets/cross.svg";
import addMore from "../../assets/plus-circle.png";
import resetLogo from "../../assets/reset.svg";
import submitLogo from "../../assets/icon-submit.svg";
import "./Modal.css"
import {v4 as uuidv4} from "uuid";
import axios from "axios";
import config from "../utils/config";

const Modal = ({ isOpen, onClose, mobile }) => {
    const [showLoading, setShowLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [sampleRequest,setSampleRequest] = useState([
        {
          id: uuidv4(),
          item: '',
          dimension: '',
          totalquantity: '',
          quantityperpacket: ''
        }
    ]);
    const [dealerInfo,setDealerInfo] = useState({
        existing: '',
        name: '',
        address: '',
        state: '',
        city: '',
        language: ''
    });
    const [errors,setErrors] = useState({});
    const [responseSampleRequest,setResponseSampleRequest] = useState("");

   
    if (!isOpen) return null; // Don't render if modal is closed
      const handleDealerType = (e) => {
        const {name,value} = e.target
        setDealerInfo({
          ...dealerInfo,
          // existing: e.target.value,
          [name]:value
        })
      }
      console.log('dealer type', dealerInfo?.existing);
    
      const addField = ()=> {
        setSampleRequest([
          ...sampleRequest,
          {
            id: uuidv4(),
            item: '',
            dimension: '',
            totalquantity: '',
            quantityperpacket: ''
          }
        ])
      }
      console.log('sample', sampleRequest)
      const removeField = (id) => {
        setSampleRequest(sampleRequest.filter((request) => {
          return (request.id !== id)
        }))
      }
      const handleSampleRequest = (e,id) => {
        console.log('id',id, sampleRequest)
        const {name,value} = e.target
        const newSampleRequest = sampleRequest.map((item)=>{
          if(item.id === id) {
            return { ...item, [name]:value }
          }
          return item
        })
        console.log('value',e.target.value, newSampleRequest)
        setSampleRequest(newSampleRequest);
      }
      const validateForm = () => {
        let errors = {};
        let showError = false;
        if (!dealerInfo.existing) {
          errors.existing = 'Dealer type is required';
        }
        if (!dealerInfo.name) {
          errors.name = 'Dealer name is required';
        }
        if (!dealerInfo.address) {
          errors.address = 'Dealer address is required';
        }
        if (!dealerInfo.state) {
          errors.state = 'State is required';
        }
        if (!dealerInfo.city) {
          errors.city = 'City is required';
        }
        if (!dealerInfo.language) {
          errors.language = 'Language is required';
        }
    
        sampleRequest.forEach((item, index) => {
          if (!item.item) {
            showError = true;
            errors[`item_${index}`] = 'Item Description is required';
          }
          if (!item.dimension) {
            showError = true;
            errors[`dimension_${index}`] = 'Item Dimension is required';
          }
          if (!item.totalquantity) {
            showError = true;
            errors[`totalquantity_${index}`] = 'Total Quantity is required';
          }
          if (!item.quantityperpacket) {
            showError = true;
            errors[`quantityperpacket_${index}`] = 'Quantity Per Packet is required';
          }
        });
    
        setErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
      };
      const handleSubmitSampleRequest = async () => {
        if(validateForm()) {
          // const payload = dealerInfo?.map((item,index)=>{
          //   return {...item, mobile:localStorage.getItem('superonUserPhNum'), sample_details:sampleRequest}
          // })
          setShowLoading(true);
          const sampleRequestPayload = sampleRequest?.map((item,index)=> {
            return {...item,mobile:mobile}
          })
          const payload = {
            ...dealerInfo,
            mobile:mobile,
            sample_details: sampleRequestPayload
          }
          console.log('payload', payload)
          // return
          try {
            let response = await axios.post(config.ipAddress + `/addsamplerequest`, payload)
            console.log('response',response?.data?.message);
            let responseData = response?.data?.message;
            // setResponseSampleRequest(responseData);
            setShowLoading(false);
            onClose()
            
            if (responseData) {
              setIsModalOpen(true);
            }
            
    
            setDealerInfo({
              existing: '',
              name: '',
              address: '',
              state: '',
              city: '',
              language: ''
            })
            setSampleRequest([
              {
                id: uuidv4(),
                item: '',
                dimension: '',
                totalquantity: '',
                quantityperpacket: ''
              }
            ])
          } catch (error) {
            setMessage("Network Error!");
            setShowErrorModal(true);
            setShowLoading(false);
            return;
          }
        }
        else {
          console.log('Validation failed');
        }
      }
      const resetSampleRequest = () => {
        setDealerInfo({
          existing: '',
          name: '',
          address: '',
          state: '',
          city: '',
          language: ''
        })
        setSampleRequest([
          {
            id: uuidv4(),
            item: '',
            dimension: '',
            totalquantity: '',
            quantityperpacket: ''
          }
        ])
      }
      

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="w-full max-w-[600px] ">
                {/* <h2 className="text-xl font-semibold mb-4">Modal Title {mobile}</h2> */}
                <div className="text-gray-700">
                    <section className="bg-white shadow-6xl border-[1px] border-gray-300 p-4 rounded-[10px]">
                        <div className="flex gap-2 mb-1 relative">
                            <div className="bg-[#FF971A] w-[2px] "></div>
                            <label htmlFor="" className="text-lg ">
                                Sample Request
                            </label>
                            <div className="absolute right-0 top-0 bg-orange-light w-5 h-5 rounded-full flex flex-wrap justify-center items-center leading-5 text-white cursor-pointer" onClick={onClose}>x</div>
                        </div>
                        <div className="overflow-y-scroll pr-2" style={{height: 'calc(100vh - 150px)'}}>
                            <div className="dealer-info">
                                <div className="field flex flex-wrap items-center mt-5 mb-5 !w-full">
                                    <label
                                        style={{ marginRight: '16px' }}
                                    >Dealer Type : </label>
                                    <label
                                        htmlFor="new"
                                        style={{ marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                                    >
                                        <input
                                            type="radio"
                                            class={`w-auto h-[auto]`}
                                            style={{ marginRight: '8px' }}
                                            value="New"
                                            name="existing"
                                            id="new"
                                            checked={dealerInfo?.existing === "New"}
                                            onChange={(e) => {
                                                handleDealerType(e)
                                                setErrors({ ...errors, existing: '' })
                                            }}
                                        />
                                        New
                                    </label>
                                    <label
                                        htmlFor="existing"
                                        style={{ marginRight: '16px', fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                                    >
                                        <input
                                            type="radio"
                                            class={`w-auto h-[auto] mr-[2]`}
                                            style={{ marginRight: '8px' }}
                                            value="Existing"
                                            name="existing"
                                            id="existing"
                                            checked={dealerInfo?.existing === "Existing"}
                                            onChange={(e) => {
                                                handleDealerType(e)
                                                setErrors({ ...errors, existing: '' })
                                            }}
                                        />
                                        Existing
                                    </label>
                                    {errors.existing && <p className="error-field w-[100%] text-[14px]">{errors.existing}</p>}
                                </div>
                                <div className="field">
                                    {/* <label>Name</label> */}
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.name ? 'error-field' : ''}`}
                                        // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                        value={dealerInfo?.name}
                                        name="name"
                                        onChange={(e) => {
                                            handleDealerType(e)
                                            setErrors({ ...errors, name: '' })
                                        }}
                                    />
                                </div>
                                <div className="field">
                                    
                                    <input
                                        type="text"
                                        placeholder="Address"
                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none  ${errors?.address ? 'error-field' : ''}`}
                                        // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                        value={dealerInfo?.address}
                                        name="address"
                                        onChange={(e) => {
                                            handleDealerType(e)
                                            setErrors({ ...errors, address: '' })
                                        }}
                                    />
                                </div>
                                <div className="field">
                                    {/* <label>State</label> */}
                                    <input
                                        type="text"
                                        placeholder="State"
                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.state ? 'error-field' : ''}`}
                                        // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                        value={dealerInfo?.state}
                                        name="state"
                                        onChange={(e) => {
                                            handleDealerType(e)
                                            setErrors({ ...errors, state: '' })
                                        }}
                                    />
                                </div>
                                <div className="field">
                                    {/* <label>City</label> */}
                                    <input
                                        type="text"
                                        placeholder="City"
                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.city ? 'error-field' : ''}`}
                                        // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                        value={dealerInfo?.city}
                                        name="city"
                                        onChange={(e) => {
                                            handleDealerType(e)
                                            setErrors({ ...errors, city: '' })
                                        }}
                                    />
                                </div>
                                <div className="field">
                                    {/* <label>Language</label> */}
                                    <input
                                        type="text"
                                        placeholder="Language"
                                        class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors?.language ? 'error-field' : ''}`}
                                        // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                        value={dealerInfo?.language}
                                        name="language"
                                        onChange={(e) => {
                                            handleDealerType(e)
                                            setErrors({ ...errors, language: '' })
                                        }}
                                    />
                                </div>
                            </div>
                        
                            <div className="sample-request">
                                {sampleRequest.map((item, index) => (
                                    <div key={item?.id} className="form-group relative">
                                        {sampleRequest.length > 1 && (
                                            <button
                                                onClick={() => removeField(item.id)}
                                                className="cursor-pointer"
                                                style={{ position: 'absolute', right: '10px', top: '10px', }}
                                            ><img src={crossIcon} alt="Delete" width="16" /></button>
                                        )}
                                        <div className="field">
                                            <label>Item Description</label>
                                            <input
                                                type="text"
                                                placeholder="Mention Complete Product Description"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`item_${index}`] ? 'error-field' : ''}`}
                                                // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                                value={item?.item}
                                                name="item"
                                                onChange={(e) => {
                                                    handleSampleRequest(e, item.id)
                                                    setErrors({ ...errors, [`item_${index}`]: '' })
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                }}
                                            />
                                        </div>
                                        <div className="field">
                                            <label>Item Dimension</label>
                                            <input
                                                type="text"
                                                placeholder="Mention Complete Product Dimension"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`dimension_${index}`] ? 'error-field' : ''}`}
                                                // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                                value={item?.dimension}
                                                name="dimension"
                                                onChange={(e) => {
                                                    handleSampleRequest(e, item.id)
                                                    setErrors({ ...errors, [`dimension_${index}`]: '' })
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                }}
                                            />
                                        </div>
                                        <div className="field">
                                            <label>Total Quantity Required</label>
                                            <input
                                                type="text"
                                                placeholder="Mention No. of Pcs/Kg"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`totalquantity_${index}`] ? 'error-field' : ''}`}
                                                // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                                value={item?.totalquantity}
                                                name="totalquantity"
                                                onChange={(e) => {
                                                    handleSampleRequest(e, item.id)
                                                    setErrors({ ...errors, [`totalquantity_${index}`]: '' })
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                }}
                                            />
                                        </div>
                                        <div className="field">
                                            <label>Quantity Per Packet</label>
                                            <input
                                                type="text"
                                                placeholder="Type here"
                                                class={`w-full h-[36px] border text-[14px] border-gray-300 py-[4px] px-1 rounded-[4px] placeholder:text-[12px] focus:outline-none ${errors[`quantityperpacket_${index}`] ? 'error-field' : ''}`}
                                                // style={{borderColor: errorObj[item?.weekday]?.area_of_operation ? "red" : "green"}}
                                                value={item?.quantityperpacket}
                                                name="quantityperpacket"
                                                onChange={(e) => {
                                                    handleSampleRequest(e, item.id)
                                                    setErrors({ ...errors, [`quantityperpacket_${index}`]: '' })
                                                    // setErrorObj({...errorObj,[item?.weekday]:{...errorObj[item?.weekday],area_of_operation:false}})
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-end items-center gap-2 mt-2">
                                <button
                                    type="button"
                                    onClick={addField}
                                    className="px-0 py-0 mt-2"
                                    style={{ marginRight: 'auto' }}
                                >
                                    {/* Add more */}
                                    <img src={addMore} width={20} alt="" />
                                </button>
                                {responseSampleRequest ? <span className="text-green-700 text-[14px]">{responseSampleRequest}</span> : ''}
                                <div className=" border-[1px] border-gray-200 w-[35px] h-[25px]  rounded-[4px] flex items-center justify-center cursor-pointer">
                                    <img
                                        src={resetLogo}
                                        alt="submit-logo"
                                        className="w-4 "
                                        onClick={() => resetSampleRequest()}
                                    />
                                </div>
                                <div className="border-[1px] flex justify-center items-center border-gray-200 w-[35px] h-[25px]  rounded-[4px] cursor-pointer">
                                    <img
                                        src={submitLogo}
                                        alt="submit-logo"
                                        className="w-[18px]  "
                                        onClick={() => handleSubmitSampleRequest()}
                                    />
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
                {/* <button
                    onClick={onClose}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                    Close
                </button> */}
            </div>
        </div>
    );
};

export default Modal;
