import { Route, Routes } from "react-router-dom";
// import Sidebar from "./component/sidebar/Sidebar";
import Dashboard from "./component/dashboard/Dashboard";
import Report from "./component/request/Report";
// import Login from "./component/login/Login";
import Upload from "./component/upload/Upload";
// import Navbar from "./component/navbar/Navbar";
import Loginpage from "./component/login/Loginpage";
// import Demo from "./component/request/Demo";
// import Drag from "./component/upload/Drag";
import "./App.css"

function App() {
  const role = JSON.parse(localStorage.getItem('role')) || []
  console.log('rollll',role)
  // role.includes("dashboard")
  return (
    <>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/" element={<Loginpage />} />
        {role.includes("dashboard") &&
          <Route path="/dashboard" element={<Dashboard />} />
        }
        
        {/* <Route path="/sidebar" element={<Sidebar />} /> */}
        {/* {role.includes("report") && */}
          <Route path="/report" element={<Report />} />
        {/* } */}
        {role.includes("upload") &&
          <Route path="/upload" element={<Upload />} />
        }
        {/* <Route path="/navbar" element={<Navbar />} /> */}
        {/* <Route path="/demo" element={<Demo />} /> */}
        {/* <Route path="/drag" element={<Drag />} /> */}
      </Routes>
    </>
  );
}

export default App;
