import React, { useEffect, useState } from 'react'
import ArrowLeft from "../../assets/keyboard_arrow_left.svg"
import "./Activity.css"
import axios from 'axios'
import config from '../utils/config'
import { Await } from 'react-router-dom'

const StatusRemark = ({closeForm,id,oldstatus}) => {
    const [statusData,setStatusData] = useState({
        itemid: id,
        oldstatus:oldstatus || "Pending",
        newstatus: oldstatus || "Pending",
        remark: ''
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStatusData((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    const [statusDropdown,setStatusDropdown] = useState([]);
    const [submitLoader,setSubmitLoader] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        const payload = statusData;

        try {
            console.log('status form',payload);
            // return
            const res = await axios.post(config.ipAddress + `/addsampleactivity`, payload)
            console.log(res)
            setSubmitLoader(false)
            closeForm()
        } catch (error) {
            setSubmitLoader(false)
        }
    
        setSubmitLoader(true)
        closeForm();
      };
      useEffect(()=> {
        const fetchStatus = async () => {
            const res = await axios.get(config.ipAddress + `/getsamplerequeststatus`)
            console.log('status',res?.data?.data?.statuses)
            const getRes = res?.data?.data?.statuses
            setStatusDropdown(getRes)
        }
        fetchStatus()
      },[]);
      console.log('statusDropdown',statusDropdown)
      console.log('status sdd', oldstatus, statusData.newstatus)
    return (
        <div className='drawer'>
            <div className="drawer-header">
                <div
                    className="flex flex-wrap justify-between items-center cursor-pointer drawer-heading"
                >
                    <img
                        src={ArrowLeft}
                        className="mr-3"
                        onClick={() => closeForm()}
                    />
                    <span
                        onClick={() => closeForm()}
                        className='mr-auto'
                    >Status Remark</span>
                </div>
            </div>
            <div className="drawer-body">
                <div className="drawer-form">
                    <div className="flex flex-wrap justify-between mb-2">
                        <label>Status</label>
                        <div className="property">
                            <select
                                className="dropdown"
                                name="newstatus"
                                value={statusData.newstatus}
                                onChange={handleChange}
                            >
                                {statusDropdown?.length &&
                                    statusDropdown.map((item)=>{
                                        return (
                                            item?.name == "Pending" ? <option value={item?.name} selected>{item?.name}</option> : <option value={item?.name}>{item?.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {statusData.newstatus == "Completed" || statusData.oldstatus == "Completed" ?
                        <>
                            <div className="flex flex-wrap justify-between mb-2">
                                <label>new</label>
                                <div className="property">
                                    <input
                                        type='text'
                                        name="new"
                                        // value={statusData.remark}
                                        onChange={handleChange}
                                        // rows={5}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between mb-2">
                                <label>new</label>
                                <div className="property">
                                    <input
                                        type='text'
                                        name="new"
                                        // value={statusData.remark}
                                        onChange={handleChange}
                                        // rows={5}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                    <div className="flex flex-wrap justify-between">
                        <label>Remark</label>
                        <div className="property">
                            <textarea
                                name="remark"
                                value={statusData.remark}
                                onChange={handleChange}
                                rows={5}
                                placeholder='Type here'
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="drawer-footer">
                <button
                onClick={() => closeForm()}
                className="btn mr-2"
                >
                Cancel
                </button>
                <button
                // className={`btn-drawer ${submitLoader ? "!cursor-default":"!cursor-pointer"}`}
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={submitLoader ? true: false}
                >
                {/* {submitLoader ? <CircleLoading />:'Submit'} */}
                Submit
                </button>
            </div>
        </div>
    )
}

export default StatusRemark
